<template>
  <section>
    <b-card>
      <b-breadcrumb>
        <div class="d-flex align-items-center">
          <span class="text-primary">Report </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>
              Report acquisti venditori</b-breadcrumb-item
            >
          </b-breadcrumb>
        </div>
      </b-breadcrumb>
    </b-card>

     <b-row class="mb-2">
      <b-col md="6">
        <b-card class="h-100">
          <b-avatar variant="light-primary" size="50">
            <span class="d-flex align-items-center">
              <feather-icon icon="CalendarIcon" size="20" />
            </span>
          </b-avatar>

          <div class="d-flex align-items-center justify-content-between mt-2">
            <div>
              <h2>Calendario acquisti</h2>
              <span>Visualizza il calendario degli acquisti</span>
            </div>

            <div>
              <b-button variant="primary-light">
                Vai al calendario
              </b-button>
            </div>
          </div>
        </b-card>
      </b-col>

      <b-col md="3">
        <b-card class="h-100">
          <div class="d-flex align-items-center">
            <b-avatar variant="light-primary" size="50">
              <span class="d-flex align-items-center">
                <feather-icon icon="TrendingUpIcon" size="20" />
              </span>
            </b-avatar>
            <span class="ml-1 text-primary h2"> 0000,00 € </span>
          </div>

          <div class="mt-2">
            <h2>Fatturato nel mese</h2>
            <span>Il fatturato nel mese corrente</span>
          </div>
        </b-card>
      </b-col>

      <b-col md="3">
        <b-card class="h-100">
          <div class="d-flex align-items-center">
            <b-avatar variant="light-primary" size="50">
              <span class="d-flex align-items-center">
                <feather-icon icon="TrendingUpIcon" size="20" />
              </span>
            </b-avatar>
            <span class="ml-1 text-primary h2"> 0000,00 € </span>
          </div>

          <div class="mt-2">
            <h2>Fatturato da inizio dell'anno</h2>
            <span>Il fatturato dall'inizio dell'anno corrente</span>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="5">
        <b-card>
          <h2>Classifica top 10 rivenditori</h2>
          <span
            >Di seguito è riportata la classifica aggiornata della top 10 dei
            migliori venditori dell'anno corrente</span
          >
        </b-card>
      </b-col>

      <b-col md="7">
        <b-row>
          <b-col md="12">
            <b-card>
              <h2>Vendita auto al rivenditore per mese</h2>

                <chartjs-component-bar-chart
                :height="100"
                :data="chartJSData.data"
                :options="chartJSData.options"
                />
            </b-card>
          </b-col>

          <b-col md="12">
            <b-card>
              <h2>Le ultime 10 vendite</h2>

              <b-row>
                <b-col class="mt-2" v-for="i in 10" :key="i" md="6">
                  <div class="d-flex">
                    <img />

                    <div>

                    <p class="h4">Marca Modello Versione</p>

                    <p class="m-0"><b>Rivenditore:</b> nome rivenditore</p>
                    <p class="m-0"><b>Rivenditore:</b> nome cliente</p>
                    <p class="m-0"><b>Data vendita:</b> gg/mm/aaaa</p>

                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BAvatar,
  BButton,
  BRow,
  BCol,
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
} from "bootstrap-vue";

import ChartjsComponentBarChart from "@/components/charts/ChartjsComponentBarChart.vue";
import { $themeColors } from '@themeConfig';

export default {
  components: {
    BAvatar,
    BButton,
    BRow,
    BCol,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    ChartjsComponentBarChart
  },

  data: () => ({

    chartJSData: {
      data: {
        labels: ["Gennario", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
        datasets: [{ data: [40, 20, 12, 13, 90, 32, 23, 54, 12, 10, 12, 9] }],
      },
      options: {
      elements: {
        rectangle: {
          borderWidth: 2,
          borderSkipped: 'bottom',
        },

        responsive: true,
        maintainAspectRatio: true,
        responsiveAnimationDuration: 500,

        legend: {
          display: false,
        },

        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          color: $themeColors.primary,
  
        },

        scales: {
        xAxes: [
          {
            display: true,
           
            scaleLabel: {
              display: false,
            },
         
          },
        ],
        yAxes: [
          {
            display: true,
          
            ticks: {
              stepSize: 200,
              min: 0,
              max: 400,
             
            },
          },
        ],
      },
    
      },
      },
    },

  }),
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

